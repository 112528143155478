import { Container } from "react-bootstrap";
import cx from "classnames";

export default function Filter() {
  return (
    <div className="activity-filter">
      <Container>
        <div className="wrapper">
          <div>
            <p className="date">29 stays . June 29 - 30</p>
            <p className="stays">Stays in selected map area</p>
          </div>
          <div className="filter-btn">
            <button className={cx("btn", { active: false })}>Cancellation flexibility</button>
            <button className={cx("btn", { active: false })}>Entire place</button>
            <button className={cx("btn", { active: true })}>Price</button>
            <button className={cx("btn", { active: false })}>Instant Book</button>
          </div>
        </div>
      </Container>
    </div>
  );
}
