import { Container, Row, Col } from "react-bootstrap";
import cx from "classnames";

const img1 =
  "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80";

const img2 =
  "https://images.unsplash.com/photo-1549517045-bc93de075e53?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3451&q=80";

const img3 =
  "https://images.unsplash.com/photo-1576201836106-db1758fd1c97?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3450&q=80";

const img4 =
  "https://images.unsplash.com/photo-1448630360428-65456885c650?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2094&q=80";

function RenderCard(props) {
  const { image = "", title = "", stays, className = "", middle = false } = props;

  return (
    <div className={cx("stay-grid-card", className, { middle })}>
      <div className="image">
        <img src={image} />
      </div>
      <div className="content">
        <h2 className="title">{title}</h2>
        <p className="stays">{stays} stays</p>
      </div>
    </div>
  );
}

export default function StaysSection() {
  return (
    <section className="stays-section">
      <Container>
        <Row>
          <Col>
            <RenderCard image={img1} title="Outdoor gateways" stays={131} />
          </Col>
          <Col>
            <RenderCard image={img2} title="Outdoor gateways" className="mb-4" middle stays={131} />
            <RenderCard image={img3} title="Entire homes" middle stays={131} />
          </Col>
          <Col>
            <RenderCard image={img4} title="Pets allowed" stays={131} />
          </Col>
        </Row>
      </Container>
    </section>
  );
}
