import { Container, Button } from "react-bootstrap";
import cx from "classnames";

const tempImage = "https://static.toiimg.com/photo/msid-75805310/75805310.jpg?135092";

function ProfileRender(props) {
  const { image = "", name = "", old = "", sm = false, className = "" } = props;
  return (
    <div className={cx("profile-card", className, { sm })}>
      <div className="profile-card-content-wrapper">
        <div className="image-wrapper">
          <img src={image} alt="" />
        </div>
        {name && (
          <div className="detail">
            <h3>{name}</h3>
            <p>{old} old</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default function BecomeAHost() {
  const content = () => (
    <div className="text-center">
      <h2>Become a Host</h2>
      <p>
        Earn extra income and unlock
        <br />
        new oppertunities by sharing
        <br /> your space.
      </p>
      <Button className="py-3 px-5">Learn More</Button>
    </div>
  );

  return (
    <section className="become-a-host py-5">
      <Container>
        <div className="wrapper">
          <div className="circle-wrapper">
            <span className="ci ci-top" />
            <span className="ci ci-bottom" />
          </div>
          <div className="main-content">
            <div className="profile-card-wrapper left">
              <ProfileRender image={tempImage} className="profile-1" name="Maria" old={24} />
              <ProfileRender image={tempImage} className="profile-2" name="Sam" old={24} />
              <ProfileRender image={tempImage} className="profile-3" sm />
              <ProfileRender image={tempImage} className="profile-4" sm />
            </div>
            <div className="content">{content()}</div>
            <div className="profile-card-wrapper right">
              <ProfileRender image={tempImage} className="profile-1" name="Maria" old={24} />
              <ProfileRender image={tempImage} className="profile-2" name="Sam" old={24} />
              <ProfileRender image={tempImage} className="profile-3" sm />
              <ProfileRender image={tempImage} className="profile-4" sm />
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
