import { Form } from "react-bootstrap";

function SearchInput(props) {
  const { label = "", placeholder = "" } = props;
  return (
    <Form.Group className="s-input">
      <Form.Label>{label}</Form.Label>
      <Form.Control type="email" placeholder={placeholder} />
    </Form.Group>
  );
}

export default function SearchFilter(props) {
  return (
    <div className="search-filter">
      <div className="input-wrapper">
        <div className="location-col">
          <SearchInput label="Location" placeholder="Where are you going?" />
        </div>
        <div className="s-cols">
          <SearchInput label="Check In" placeholder="Add Dates" />
        </div>
        <div className="s-cols">
          <SearchInput label="Check Out" placeholder="Add Dates" />
        </div>
        <div className="s-cols">
          <SearchInput label="Guests" placeholder="Add guests" />
        </div>
      </div>
      <div className="btn-wapper">
        <button className="btn btn-search">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  );
}
