import Layout from "../components/layout";
import { Home, Activities } from "../pages";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

function Routes() {
  return (
    <Router>
      <Switch>
        <Layout>
          <Route exact path="/" component={Home} />
          <Route exact path="/activities" component={Activities} />
        </Layout>
      </Switch>
    </Router>
  );
}

export default Routes;
