import { Container, Row, Col } from "react-bootstrap";

export default function DetailHeader(props) {
  return (
    <div className="detail-header">
      <Container>
        <Row>
          <Col md={5} xl={6} className="mb-3 mb-md-0">
            <p className="type">Location</p>
            <p className="name">Welligton City, Welligton, New Zealand</p>
          </Col>
          <Col md={7} xl={6}>
            <Row>
              <Col xs={4} md={4} xl={4}>
                <p className="type">Check in</p>
                <p className="name">June 29</p>
              </Col>
              <Col xs={4} md={4} xl={4}>
                <p className="type">Check out</p>
                <p className="name">Jul 5</p>
              </Col>
              <Col xs={4} md={4} xl={4}>
                <p className="type">Guests</p>
                <p className="name">2 Guests</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
