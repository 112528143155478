import { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import cx from "classnames";
import { mockrenderData1, mockrenderData2, mockrenderData3 } from "./mockdata";

function GatewayContent(props) {
  const { optionData = [] } = props;
  return (
    <div>
      <Row>
        {optionData.map(({ name, place }) => (
          <Col sm={6} md={4} lg={3} xl={2}>
            <p className="mb-0">
              <b>{name}</b>
            </p>
            <p>{place}</p>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default function Gateways() {
  const tabsData = [
    { id: 1, title: "Destination for arts and culture" },
    { id: 2, title: "Destinations for outdoor adventures" },
    { id: 3, title: "Mountain cabins" },
    { id: 4, title: "Beach destinations" },
  ];

  const [activeTab, setActiveTab] = useState(1);

  return (
    <section className="gateways-section">
      <Container>
        <h2 className="sec-title mb-5">Inspiration for future gateways</h2>

        <div className="tabls-btns">
          {tabsData.map(({ title, id }) => (
            <span>
              <button
                className={cx("btn", { active: activeTab === id })}
                onClick={() => setActiveTab(id)}
              >
                {title}
              </button>
            </span>
          ))}
        </div>

        <div>
          {activeTab === 1 && (
            <GatewayContent
              optionData={[...mockrenderData1, ...mockrenderData2, ...mockrenderData3]}
            />
          )}
          {activeTab === 2 && (
            <GatewayContent
              optionData={[...mockrenderData2, ...mockrenderData1, ...mockrenderData3]}
            />
          )}
          {activeTab === 3 && (
            <GatewayContent
              optionData={[...mockrenderData3, ...mockrenderData2, ...mockrenderData1]}
            />
          )}
          {activeTab === 4 && (
            <GatewayContent
              optionData={[...mockrenderData3, ...mockrenderData1, ...mockrenderData2]}
            />
          )}
        </div>
      </Container>
    </section>
  );
}
