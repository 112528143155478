import BecomeAHost from "../../components/BecomeAHost";
import StaysSection from "../../components/StaysSection";
import Banner from "./Banner";
import ExploreNearby from "./ExploreNearby";
import Gateways from "./Gateways";

export default function Home() {
  return (
    <>
      <Banner />
      <StaysSection />
      <ExploreNearby />
      <BecomeAHost />
      <Gateways />
    </>
  );
}
