import { Container, Row, Col } from "react-bootstrap";

const tempImage =
  "https://images.unsplash.com/photo-1522775559573-2f76d540932b?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=300&q=80";

const mockData = [
  {
    title: "San Francisco",
    drive: "3 Hour drive",
  },
  {
    title: "Los Angles",
    drive: "1 Hour drive",
  },
  {
    title: "Miami",
    drive: "4.5 Hour drive",
  },
  {
    title: "Washington",
    drive: "7 Hour drive",
  },
  {
    title: "New York",
    drive: "4 Hour drive",
  },
  {
    title: "Chicago",
    drive: "5 Hour drive",
  },
  {
    title: "San Jose",
    drive: "3 Hour drive",
  },
  {
    title: "delphiaila",
    drive: "4 Hour drive",
  },
];

function RenderCard(props) {
  const { title = "", drive = "" } = props;
  return (
    <div className="explore-card">
      <div>
        <img src={tempImage} />
      </div>
      <div className="ps-3">
        <h2 className="title">{title}</h2>
        <p>{drive}</p>
      </div>
    </div>
  );
}

export default function ExploreNearby() {
  return (
    <section className="py-5">
      <Container>
        <h2 className="sec-title mb-5">Explore nearby</h2>
        <Row>
          {mockData.map((data, index) => (
            <Col sm={6} lg={4} xl={3} className="mb-5" key={index}>
              <RenderCard {...data} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
