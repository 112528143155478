import SearchFilter from "../../../components/SearchFilter";
import Slider from "./slider";

export default function Banner() {
  return (
    <div className="position-relative">
      <Slider />
      <div className="banner-filter-holder">
        <SearchFilter />
      </div>
    </div>
  );
}
