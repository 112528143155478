export const mockrenderData1 = [
  {
    name: "Phoenix",
    place: "Arizona",
  },
  {
    name: "SanFrancisco",
    place: "Califoria",
  },
  {
    name: "Keswick",
    place: "England",
  },
  {
    name: "York",
    place: "England",
  },
  {
    name: "Dubline",
    place: "Ireland",
  },
  {
    name: "Garnde Isle",
    place: "Louisiana",
  },
];

export const mockrenderData2 = [
  {
    name: "Duluth",
    place: "Minnesota",
  },
  {
    name: "Hot Sprimgd",
    place: "Arkansas",
  },
  {
    name: "Barcelona",
    place: "Catalonia",
  },
  {
    name: "London",
    place: "England",
  },
  {
    name: "Paris",
    place: "France",
  },
  {
    name: "Florence",
    place: "Italy",
  },
];

export const mockrenderData3 = [
  {
    name: "Berlin",
    place: "Germany",
  },
  {
    name: "Athens",
    place: "Greece",
  },
  {
    name: "Istambul",
    place: "Turkey",
  },
  {
    name: "Milan",
    place: "Italy",
  },
  {
    name: "Rome",
    place: "Italy",
  },
  {
    name: "Marid",
    place: "Spain",
  },
];
