import { useRef } from "react";
import { Button } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel2";

const tempImage =
  "https://images.unsplash.com/photo-1476725994324-6f6833ea0631?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80";
const tempImage2 =
  "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2100&q=80";
const tempImage3 =
  "https://images.unsplash.com/photo-1546525002-c530a3dc8efd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1866&q=80";

export default function Slider() {
  const refferencr = useRef(null);
  const options = {
    items: 1,
    nav: true,
    loop: true,
    // rewind: true,
    autoplay: true,
  };

  const events = {
    onDragged: function (event) {},
    onChanged: function (event) {},
  };

  const slidedata = [
    {
      image: tempImage,
    },
    {
      image: tempImage2,
    },
    {
      image: tempImage3,
    },
  ];

  return (
    <div className="home-banner">
      <OwlCarousel ref={refferencr} options={options} events={events}>
        {slidedata.map(({ image }, index) => (
          <div key={index} className="banner-image-holder">
            <div className="banner-image-wrapper">
              <img className="main-image" src={image} alt="" />
            </div>
            <div className="content">
              <h2>The Greatest Outdoors</h2>
              <p>Wishlists curated by Roomsy</p>
              <Button variant="dark"> Get inspired</Button>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
}
