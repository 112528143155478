import logo from "../../../assets/images/logo.png";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";

const MapMenu = ({ menus = [] }) =>
  menus.map(({ name, link }) => (
    <div className="mb-2">
      <Link to={link}>{name}</Link>
    </div>
  ));

export default function Footer() {
  const footerHead = () => (
    <div className="footer-head">
      <Container>
        <div className="wrapper">
          <div className="store">
            <button>
              <i className="fab fa-apple" />
              <div>
                <p className="on-it">Available on the</p>
                <p className="store-name">Appstore</p>
              </div>
            </button>
            <span className="sperator" />
            <button>
              <i className="fab fa-google-play" />
              <div>
                <p className="on-it">Get it on</p>
                <p className="store-name">Google Play</p>
              </div>
            </button>
          </div>
          <div>
            <img src={logo} style={{ height: 40 }} alt="logo" />
          </div>
          <div>
            <Button variant="dark">Become a host</Button>
          </div>
        </div>
      </Container>
    </div>
  );

  const menuItems = [
    {
      name: "Lorem",
      link: "/",
    },
    {
      name: "Lorem",
      link: "/",
    },
    {
      name: "Lorem",
      link: "/",
    },
    {
      name: "Lorem",
      link: "/",
    },
  ];

  const mainFooter = () => (
    <footer id="footer">
      <Container>
        <Row>
          <Col sm={6} lg={3}>
            <h2>ABOUT</h2>
            <MapMenu menus={menuItems} />
          </Col>
          <Col sm={6} lg={3}>
            <h2>COMMUNITY</h2>
            <MapMenu menus={menuItems} />
          </Col>
          <Col sm={6} lg={3}>
            <h2>HOST</h2>
            <MapMenu menus={menuItems} />
          </Col>
          <Col sm={6} lg={3}>
            <h2>SUPPORT</h2>
            <MapMenu menus={menuItems} />
          </Col>
        </Row>
      </Container>
    </footer>
  );

  const subFooter = () => (
    <div className="sub-footer">
      <Container>
        <div className="wrapper">
          <div className="copy-links">
            <p className="mb-0 me-3">© 2021 Roomsy, Inc</p>
            <Link to="/">Privacy</Link>
            <span>.</span>
            <Link to="/">Terms</Link>
            <span>.</span>
            <Link to="/">Sitemap</Link>
          </div>
          <div className="social-links">
            <a href="#">
              <i className="fab fa-facebook-square" />
            </a>
            <a href="#">
              <i className="fab fa-twitter-square" />
            </a>
            <a href="#">
              <i className="fab fa-instagram-square" />
            </a>
          </div>
        </div>
      </Container>
    </div>
  );

  return (
    <>
      {footerHead()}
      {mainFooter()}
      {subFooter()}
    </>
  );
}
