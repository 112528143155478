const tempImage =
  "https://images.unsplash.com/photo-1549517045-bc93de075e53?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3451&q=80";

export default function InfoCard() {
  return (
    <div className="info-card">
      <div className="imageWrapper">
        <img className="image" src={tempImage} alt="" />
        <p className="rate">4.5</p>
      </div>
      <div className="content">
        <h3 className="title">Tia Hut</h3>
        <p className="dec">Entire cabin in Dyerville</p>
        <p className="detail">Studio . 1 bed - 1 bath</p>
        <p className="price">S$164/ night</p>
      </div>
    </div>
  );
}
