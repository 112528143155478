import { Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/logo.png";
import userIcon from "../../../assets/images/icons/user.png";

export default function Header() {
  const menus = [
    {
      name: "Place to stay",
      link: "/",
    },
    {
      name: "Experiences",
      link: "/",
    },
    {
      name: "Nearby",
      link: "/",
    },
  ];

  return (
    <header id="header">
      <Container>
        <div className="wrapper">
          <div className="menu-wrapper">
            <Link to="/">
              <img src={logo} style={{ height: 40 }} alt="logo" />
            </Link>
            <span className="seprator" />
            <ul className="menu-items">
              {menus.map(({ name, link }, index) => (
                <li key={index}>
                  <Link to={link}>{name}</Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <Button>
              <i className="far fa-user me-2" />
              Sign in
            </Button>
          </div>
        </div>
      </Container>
    </header>
  );
}
