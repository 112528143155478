import { Container, Row, Col } from "react-bootstrap";
import InfoCard from "../../components/cards/InfoCard";
import DetailHeader from "../../components/DetailHeader";
import Filter from "./Filter";
import cx from "classnames";

const img1 =
  "https://images.unsplash.com/photo-1568605114967-8130f3a36994?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80";

const img2 =
  "https://images.unsplash.com/photo-1549517045-bc93de075e53?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3451&q=80";

const img3 =
  "https://images.unsplash.com/photo-1576201836106-db1758fd1c97?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3450&q=80";

const img4 =
  "https://images.unsplash.com/photo-1448630360428-65456885c650?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2094&q=80";

const tempimage =
  "https://images.unsplash.com/photo-1476725994324-6f6833ea0631?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2100&q=80";

function RenderCard(props) {
  const { image = "", title = "", stays, className = "", middle = false } = props;

  return (
    <div className={cx("stay-grid-card-activity", className, { middle })}>
      <div className="image">
        <img src={image} />
      </div>
      <div className="content">
        <h2 className="title">{title}</h2>
        <p className="stays">{stays} stays</p>
      </div>
    </div>
  );
}

function GetPro() {
  return (
    <section className="go-pro-banner">
      <Container>
        <div className="content-wrapper" style={{ backgroundImage: `url(${tempimage})` }}>
          <div className="content">
            <h2>
              Thoughtfully designed homes.
              <br />
              Exceptional hosts. Verified for quality.
            </h2>
            <p>
              Everry home is verified through in-person quality inspection to ensure quality and
              design. Just look for the <span className="bg-primary">PRO</span> badge.
            </p>
            <button className="btn">Get PRO</button>
          </div>
        </div>
      </Container>
    </section>
  );
}

export default function Activities() {
  return (
    <>
      <DetailHeader />
      <Filter />
      <section>
        <Container>
          <Row>
            {[{}, {}, {}, {}].map(() => (
              <Col className="mb-3" md={6} lg={3}>
                <InfoCard />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <GetPro />
      <section>
        <Container>
          <Row>
            {[{}, {}, {}, {}, {}, {}, {}, {}].map(() => (
              <Col className="mb-3" md={6} lg={3}>
                <InfoCard />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="py-5">
        <Container>
          <Row>
            <Col md={6} lg={4}>
              <RenderCard image={img1} title="Outdoor gateways" stays={131} />
            </Col>
            <Col md={6} lg={4}>
              <RenderCard image={img2} title="Outdoor gateways" stays={131} />
            </Col>
            <Col md={6} lg={4}>
              <RenderCard image={img3} title="Outdoor gateways" stays={131} />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row>
            {[{}, {}, {}, {}, {}, {}, {}, {}].map(() => (
              <Col className="mb-3" md={6} lg={3}>
                <InfoCard />
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
}
